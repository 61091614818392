/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * rift-soft:
 *   - http://typekit.com/eulas/00000000000000003b9adf12
 *   - http://typekit.com/eulas/00000000000000003b9adf19
 *   - http://typekit.com/eulas/00000000000000003b9adf1d
 *   - http://typekit.com/eulas/00000000000000003b9adf22
 *   - http://typekit.com/eulas/00000000000000003b9adf26
 *
 * © 2009-2019 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-10-15 23:37:09 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=qcg2plq&ht=tk&f=28969.28976.28980.28985.28989&a=8271584&app=typekit&e=css");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&family=Source+Sans+Pro:wght@300;400;700&display=swap");

@font-face {
    font-family:"rift-soft";
    src:url("https://use.typekit.net/af/58d868/00000000000000003b9adf12/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/58d868/00000000000000003b9adf12/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/58d868/00000000000000003b9adf12/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:300;
}

@font-face {
    font-family:"rift-soft";
    src:url("https://use.typekit.net/af/f49484/00000000000000003b9adf19/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f49484/00000000000000003b9adf19/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f49484/00000000000000003b9adf19/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:400;
}

@font-face {
    font-family:"rift-soft";
    src:url("https://use.typekit.net/af/b0a7b5/00000000000000003b9adf1d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/b0a7b5/00000000000000003b9adf1d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/b0a7b5/00000000000000003b9adf1d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:500;
}

@font-face {
    font-family:"rift-soft";
    src:url("https://use.typekit.net/af/3f05ad/00000000000000003b9adf22/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/3f05ad/00000000000000003b9adf22/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/3f05ad/00000000000000003b9adf22/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:600;
}

@font-face {
    font-family:"rift-soft";
    src:url("https://use.typekit.net/af/a346fd/00000000000000003b9adf26/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/a346fd/00000000000000003b9adf26/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/a346fd/00000000000000003b9adf26/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;
    font-style:normal;
    font-weight:700;
}

.tk-rift-soft { font-family: "rift-soft",sans-serif; }
